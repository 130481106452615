import { useAuthStore } from "~/stores/auth.store";

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const authStore = useAuthStore();

  // not signed in
  if (!authStore.isSignedIn) {
    return navigateTo("/auth/sign-in");
  }
});
